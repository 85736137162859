module.exports = {
  pathPrefix: '/',
  title: 'Rémy Disch - Réalisateur - Paris, France',
  titleAlt: 'Rémy Disch',
  description: 'Remy Disch est réalisateur, directeur artistique et peintre. Il vit et travaille à Paris.',
  headline: 'Rémy Disch',
  url: 'https://www.remydisch.com',
  logo: '/logos/remy-disch11.png',
  ogLanguage: 'fr_FR',

  // Prismic.io
  PrismicRepository: 'remydisch',

  // JSONLD / Manifest
  favicon: 'static/favicon.png',
  shortName: 'remydisch',
  author: 'Rémy Disch',
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',
  facebook: 'remy.disch',
  googleAnalyticsID: 'UA-75594045-1',
  skipNavId: 'reach-skip-nav'
}
