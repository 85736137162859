import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import i18n from '../../config/i18n'

const SEO = ({ 
  title, 
  desc, 
  pathname, 
  article, 
  modalBanner,
  banner
}) => {
  const { 
    site, 
    prismicHomepageimage
  } = useStaticQuery(query)
  const { defaultTitle, defaultDescription, siteLanguage, headline } = i18n['fr-fr']
  
  const {
    buildTime,
    siteMetadata: { 
      siteUrl, 
      ogLanguage, 
      author, 
      facebook 
    },
  } = site
  
  const homeURL = `${siteUrl}`
  const ogImage = banner || prismicHomepageimage.data.image.localFile.childImageSharp.original.src;
  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${ogImage}`,
    url: `${siteUrl}${pathname || ''}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `${homeURL}${pathname}`,
    headline: title || headline.headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: `${homeURL}${pathname}`,
    description: desc || defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2021-04-20T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: ogImage,
    },
  }

  // Initial SiteNavigation
  const itemListElement = [
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-news",
      "name": "Actualités",
      "url": `${homeURL}/actualites/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-press",
      "name": "Presse",
      "url": `${homeURL}/presse/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-projects",
      "name": "Projets",
      "url": `${homeURL}/projets/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-releases",
      "name": "Réalisations",
      "url": `${homeURL}/realisations/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-photos",
      "name": "Photographies",
      "url": `${homeURL}/photographies/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-lesz",
      "name": "Les Z",
      "url": `${homeURL}/les-z/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-about",
      "name": "À propos",
      "url": `${homeURL}/a-propos/`
    },
  ]

  const SiteNavigation = {
    '@context': 'http://schema.org',
    "@graph": itemListElement
  }

  return (
    <>
      <Helmet
        title={seo.title}
        style={[{
          "cssText": `
            html,
            body {
              position: ${modalBanner ? 'fixed' : 'relative'};
              height: ${modalBanner ? '100%' : 'auto'}
              overflow: ${modalBanner ? 'hidden!important' : 'auto'}
            }
          `
        }]}
      >
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="title" content={seo.title} />
        <link rel="canonical" href={seo.url}></link>
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
        <script type="application/ld+json">{JSON.stringify(SiteNavigation)}</script>
      </Helmet>

      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
export default connect(mapStateToProps)(SEO);

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  pathname: null,
  article: false,
  node: null,
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        ogLanguage
        author
        facebook
      }
    }
    prismicHomepageimage {
      data {
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`