import styled from '@emotion/styled'

const MainWrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 2rem auto 2rem;
  padding: 3rem 2rem;
  a {
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  p {
    text-align: justify;
    em {
      font-size:18px;
      line-height: 18px
    }
  }
  &:after {
    clear:both;
    display:block;
    content: "";
    width: 100%;
    height: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 1.5rem;
  }
`

export default MainWrapper
