const website = require('./website')

module.exports = {
  'fr-fr': {
    default: true,
    path: 'fr',
    locale: 'fr-fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline
  },
}
